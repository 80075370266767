export const ONE_MINUTE = 60 * 1000;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;

const shortTime = new Intl.DateTimeFormat('en', {
  timeStyle: 'short',
});

const shortRelativeTime = (timezone) =>
  new Intl.DateTimeFormat('en', {
    timeStyle: 'short',
    timeZone: timezone,
  });

const parseTime = (dateString) => {
  const date = new Date(dateString);
  return shortTime.format(date).toLowerCase();
};

const parseToRelativeTime = (dateString, timezone) => {
  const date = new Date(dateString);
  return shortRelativeTime(timezone).format(date).toLowerCase();
};

const getUTCStamp = (dateString) => {
  const date = new Date(dateString);
  return date.getTime();
};

// combine a date and a time
const combineDateTime = (date, timestring) => {
  if (!date || !timestring) {
    return null;
  }
  const resultDate = new Date(date);
  const [time, suffix] = timestring.split(' ');
  let [hours, minutes] = time.split(':').map((d) => parseInt(d, 10));
  if (suffix === 'pm') {
    hours = 12 + (hours % 12);
  }
  resultDate.setHours(hours);
  resultDate.setMinutes(minutes);
  resultDate.setSeconds(0, 0);
  return resultDate;
};

// Get a new date by adding time to the given date. Time must be in ms
const addTime = (timeToAdd, date = new Date()) =>
  new Date(date.getTime() + timeToAdd);

// Get datestring in YYYY-MM-DD   from a date object
const getDatestring = (date = new Date()) => date.toISOString().substr(0, 10);

// Format time in HH:MM am/pm format
const formatTime = (time) =>
  time.toLocaleTimeString('en-US', { timeStyle: 'short' }).toLowerCase();

export {
  parseTime,
  getUTCStamp,
  combineDateTime,
  addTime,
  getDatestring,
  formatTime,
  parseToRelativeTime,
};
